

.full-page.countdown-end {
  @media screen and (max-width: 600px) {
	margin-top: 177px;
  }
}

.html-content
  width: 100%

.btn-bnn-default
  padding: 0 1.25rem !important
  font-size: 1rem !important

.header-start-in {
    font-size: 18px
    line-height: 20px
    margin-bottom: 8px

    @media screen and (min-width: 768px) {
        font-size: 24px
        line-height: 42px
    }
}
